import { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import {
  ArrowDownTrayIcon,
  ArrowPathIcon,
  PrinterIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";
import { PhotoView } from "react-photo-view";
import { CSVLink } from "react-csv";
import { Spinner } from "@material-tailwind/react";
import { notification, Input, Select, MenuProps, Dropdown } from "antd";
import debounce from "lodash/debounce";
import BaseService from "../../helpers/baseService";
import { DateSort } from "../../components/general/TableSortFunctions";
import { GenerateId } from "../../functions/functions";
import { suspend_user } from "../users/app_users/suspend_user";
import { StorageBox } from "../../helpers/storage";
import { LoadingDataComponent } from "../../components/general/loadingDataComponent";
import NoDataComponent from "../../components/general/noDataComponent";
import { CiEdit } from "react-icons/ci";
import { IoMdMore } from "react-icons/io";
import { Tooltip } from "antd";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px",
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
};

const NotificationTable = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<any>([]);
  const [total, setTotal] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [skip, setSkip] = useState<number>(0);

  const [selectedDrivers, setSelectedDrivers] = useState<any>([]);
  const [isBusy, setIsBusy] = useState<any>(false);
  const [reFetch, setReFetch] = useState<boolean>(false);
  const [CSVUsers, setCSVUsers] = useState<any>([]);

  const [userName, setUserName] = useState<any>(`{}`);
  const [resolvedData, setresolvedData] = useState<any>(`{}`);
  const [userPhone, setUserPhone] = useState("");
  const [readingLoading, setreadingLoading] = useState<boolean>(false);

  const getNotifications = async (lim?: number, sk?: number) => {
    setLoading(true);
    try {
      const response = await BaseService.get_api_v2(
        `/yarp/notifications/realtime?sort={"updatedAt":-1}&limit=${lim}&skip=${sk}&filter={"$and":[${resolvedData},${userName}]}`
      );
      console.log("---all notification:", response?.data.payload);
      setTotal(response?.data?.total);
      setUsers(response?.data?.payload);
    } catch (error: any) {
      console.log("notification error:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getNotifications(limit, skip);
  }, [reFetch, limit, skip, userName, resolvedData, userPhone]);

  const handleMenuClick = (e: any, rowId: any) => {
    if (e.key === "1") {
      // setEditOpen(true);
      // setGradeId(rowId);
    }
  };

  const handleRowChange = (a: any) => {
    setLimit(a);
  };

  const pageChange = (a: number) => {
    const newSkip = a === 1 ? 0 : (a - 1) * limit;
    setSkip(newSkip);
  };

  const columns: any = [
    {
      name: "Full Name",
      sortable: true,
      cell: (row: any) => {
        return (
          <section className="flex justify-center items-center">
            <PhotoView
              src={
                row?.userData?.image ||
                "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
              }
            >
              <Image
                src={
                  row?.userData?.image
                    ? row?.userData?.image
                    : "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                }
                fluid
                style={{
                  width: "40px",
                  height: "40px",
                  objectFit: "cover",
                  borderRadius: "100%",
                  marginRight: "10px",
                }}
              />
            </PhotoView>
            <div>
              {`${row?.userData?.first_name} ${row?.userData?.last_name}` ||
                "N/A"}
            </div>
          </section>
        );
      },
    },
    {
      name: "PURPOSE",
      selector: (row: { purpose: any }) => row.purpose,
      sortable: true,
    },
    {
      name: "STATUS",
      selector: (row: { resolvedFacilitatorData: any }) => {
        const isStatus =
          row?.resolvedFacilitatorData != null ? "Resolved" : "Unresolved";
        const backgroundColor =
          isStatus === "Resolved"
            ? "rgba(119, 209, 164, 0.1)"
            : "rgba(255, 99, 141, 0.1)";
        const textColor = isStatus === "Resolved" ? "#77D1A4" : "#FF638D";

        return (
          <div
            className=" flex justify-center items-center  w-auto rounded-[30px] h-[30px] px-[16px] pb-[3px]"
            style={{ backgroundColor, color: textColor }}
          >
            {isStatus}
          </div>
        );
      },
      sortable: true,
    },

    {
      name: "CREATED ON",
      cell: (row: { createdAt: moment.MomentInput }) =>
        moment(row?.createdAt).format("Do MMM YYYY - hh:mm A"),
      sortable: true,
      sortFunction: DateSort,
    },
    {
      name: "UPDATED ON",
      cell: (row: { updatedAt: moment.MomentInput }) =>
        moment(row?.updatedAt).format("Do MMM YYYY - hh:mm A"),
      sortable: true,
      sortFunction: DateSort,
    },
    {
      name: "ADMINS",
      sortable: true,
      cell: (row: any) => {
        return (
          <section className="flex justify-center items-center">
            <PhotoView
              src={
                row?.resolvedFacilitatorData?.image ||
                "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
              }
            >
              <Tooltip placement="bottom" title={"Facilitator"}>
                <Image
                  src={
                    row?.resolvedFacilitatorData?.image
                      ? row?.resolvedFacilitatorData?.image
                      : "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                  }
                  fluid
                  style={{
                    width: "40px",
                    height: "40px",
                    objectFit: "cover",
                    borderRadius: "100%",
                    marginRight: "10px",
                  }}
                />
              </Tooltip>
            </PhotoView>
            <PhotoView
              src={
                row?.readFacilitatorData?.image ||
                "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
              }
            >
              <Tooltip placement="bottom" title={"Reader"}>
                <Image
                  src={
                    row?.readFacilitatorData?.image
                      ? row?.readFacilitatorData?.image
                      : "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                  }
                  fluid
                  style={{
                    width: "40px",
                    height: "40px",
                    objectFit: "cover",
                    borderRadius: "100%",
                    marginRight: "10px",
                  }}
                />
              </Tooltip>
            </PhotoView>
          </section>
        );
      },
    },
  ];

  const handleOnlineFilter = (status: any) => {
    console.log(status);
    if (status === "Resolved") {
      setresolvedData(`{"resolvedFacilitatorData" : {"$ne" : null}}`);
    } else if (status === "Unresolved") {
      setresolvedData(`{"resolvedFacilitatorData" : {"$eq" : null}}`);
    } else {
      setresolvedData(`{}`);
    }
  };

  const handlerUserName = (e: any) => {
    if (e.target.value) {
      setUserName(
        `{"userData.full_name": {"$regex":${JSON.stringify(
          e.target.value
        )},"$options":"i"}}`
      );
    } else {
      setUserName(`{}`);
    }
  };
  const handleStatusChange = async (id: any) => {
    try {
      const response = await BaseService.put_api_v2(
        "/yarp/notifications/realtime/read",
        { notificationId: id }
      );
      setreadingLoading(true);
      console.log("Reading read", response.data);
    } catch (error: any) {
      setreadingLoading(false);
      console.log("Error reading notification", error.message);
    }
  };

  return (
    <>
      <div className="flex justify-between items-center mb-3">
        <div className="flex gap-3 items-center">
          <Tooltip placement="top">
            <button
              className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center"
              onClick={() => setReFetch((prev) => !prev)}
            >
              <ArrowPathIcon className="w-5 h-5 text-yarpBlue" />
            </button>
          </Tooltip>

          <div>
            <Select
              className="h-[40px] w-[200px]"
              options={[
                { value: "Resolved", label: "Resolved" },
                { value: "Unresolved", label: "Unresolved" },
              ]}
              placeholder="Resolved / Unresolved"
              onChange={(selected) => handleOnlineFilter(selected)}
              allowClear
            />
          </div>
          <div className="w-[200px]">
            <Input
              className="h-[40px] w-full"
              onChange={(e) => handlerUserName(e)}
              placeholder="Customer's Name"
              allowClear
            />
          </div>
        </div>
      </div>

      <DataTable
        data={users}
        columns={columns}
        progressPending={loading}
        paginationTotalRows={total}
        progressComponent={<LoadingDataComponent />}
        noDataComponent={<NoDataComponent text="No users found" />}
        pagination
        paginationServer
        onChangeRowsPerPage={handleRowChange}
        onChangePage={pageChange}
        pointerOnHover
        onRowClicked={(row: any) => {
          handleStatusChange(`${row._id}`);
          navigate(`/notifications/${row.userid}/${row.tripId}`);
        }}
        onSelectedRowsChange={(data) => setSelectedDrivers(data?.selectedRows)}
        customStyles={customStyles}
      />
    </>
  );
};

export default NotificationTable;
