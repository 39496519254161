import { useEffect } from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useParams } from "react-router-dom";
import BaseService from "../../helpers/baseService";
import { LoadingDataComponent } from "../../components/general/loadingDataComponent";
import {
  ArrowDownTrayIcon,
  ArrowPathIcon,
  PrinterIcon,
} from "@heroicons/react/24/outline";
import { Empty } from "antd";
import UpdateStatusModal from "./Modals/UpdateStatusModal";
import SmsModal from "./Modals/SmsModal";
import MapModal from "./Modals/MapModal";
import DetailsModal from "./Modals/DetailsModal";
import useColumns from "./customhooks/useColumns";
import UnassignDriverModal from "./Modals/UnassignDriverModal";

const TrackingTable = () => {
  const { id } = useParams();
  const [reFetch, setReFetch] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tracks, setTracks] = useState([]);
  const [mapShow, setMapShow] = useState<boolean>(false);
  const [detailShow, setDetailShow] = useState<boolean>(false);
  const [detail, setDetail] = useState<any>([]);
  const [tripForModal, setTripForModal] = useState<any>([]);
  const [total, setTotal] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [skip, setSkip] = useState<number>(0);
  const [OnRoadData, setOnRoadData] = useState<Array<any>>([]);
  const [openSMS, setOpenSMS] = useState<boolean>(false);
  const [openStatusUpdate, setOpenStatusUpdate] = useState<boolean>(false);
  const [SmsNumber, setNumber] = useState<any>("");
  const [RowID, setRowID] = useState<any>("");
  const [StatusOfTrip, setStatusOfTrip] = useState<any>();
  const [pickupLocation, setPickupLocation] = useState<string>();
  const [deleiveryLocation, setDeleiveryLocation] = useState<string>();
  const [driverName, setDriverName] = useState<string>();
  const columns = useColumns(handleMenuClick);
  const [openUnAssignedmodal, setUnAssignedmodal] = useState<boolean>(false);
  const [DeliveryLocationLatLng, setDeliveryLocationLatLng] = useState<any>({});
  const [PickupLocationLatLong, setPickupLocationLatLong] = useState<any>({});
  const OnRoads = async (lim: number, sk: number) => {
    setLoading(true);
    try {
      let tripsOnRoad = `/yarp/trip/onraod-admin?sort={"updated_at":-1}&limit=${lim}&skip=${sk}`;
      const response = await BaseService.get_api_v2(tripsOnRoad);
      console.log("tripsOnRoad ", response?.data?.payload);
      setOnRoadData(response?.data?.payload);
      setTracks(response?.data?.payload);
      setTotal(response?.data?.total || 0);
    } catch (error: any) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    OnRoads(limit, skip);
  }, [reFetch, limit, skip]);

  function handleMenuClick(e: any, row: any, index: any) {
    const selectedTrip = tracks[index];
    switch (e.key) {
      case "1":
        setTripForModal(selectedTrip);
        setMapShow(true);
        setDeliveryLocationLatLng({
          lat: row?.delivery_latitude,
          lng: row?.delivery_longitude,
        });
        setPickupLocationLatLong({
          lat: row?.pickup_latitude,
          lng: row?.pickup_longitude,
        });
        setDetail(row);
        break;
      case "2":
        setOpenSMS(true);
        setNumber(row?.driver_data?.phone || "");
        setPickupLocation(row?.pickup_place_name || "");
        setDeleiveryLocation(row?.delivery_place_name || "");
        setDriverName(row?.driver_data?.full_name || "");
        break;
      case "3":
        setOpenStatusUpdate(true);
        setRowID(row?._id);
        setStatusOfTrip(row?.current_event_data?.status);
        break;
      case "4":
        setDetail(row);
        setDetailShow(true);
        break;
      case "5":
        setDetail(row);
        setUnAssignedmodal(true);
        break;
      default:
        break;
    }
  }

  //handleReftch
  const handleReftch = () => {
    setReFetch(!reFetch);
  };

  const closeModal = () => {
    setMapShow(false);
    setTripForModal([]);
  };

  const closeDetailModal = () => {
    setDetail([]);
    setDetailShow(false);
    if (id) {
      window.location.href = "/tracking";
    }
  };

  //handleViewMap
  const handleViewMap = () => {
    setDetailShow(false);
    setMapShow(true);
  };

  const handleRowChange = (newLimit: number) => setLimit(newLimit);
  const pageChange = (page: number) => setSkip((page - 1) * limit);

  return (
    <>
      <div className="d-flex justify-content-between mb-3">
        <div>
          <button
            className="h-10 w-10 border-[1px] border-yarpBlue rounded-[4px] flex justify-center items-center"
            onClick={() => handleReftch()}
          >
            <ArrowPathIcon className="h-5 w-5 text-yarpBlue" />
          </button>
        </div>
        <div className="flex gap-3">
          <div>
            <Form className="d-flex flex-row-reverse">
              <input
                type="text"
                placeholder="Search.."
                className="form-control search_table h-10"
                style={{ width: "100%" }}
              />
            </Form>
          </div>
          <div className="flex gap-3">
            <button className="h-10 w-10 border-[1px] border-yarpBlue rounded-[4px] flex justify-center items-center">
              <PrinterIcon className="h-5 w-5 text-yarpBlue" />
            </button>
            <button
              type="submit"
              className="h-10 w-10 border-[1px] border-yarpBlue rounded-[4px] flex justify-center items-center"
            >
              <ArrowDownTrayIcon className="h-5 w-5 text-yarpBlue" />
            </button>
          </div>
        </div>
      </div>

      <DataTable
        columns={columns}
        data={OnRoadData}
        progressPending={loading}
        progressComponent={<LoadingDataComponent />}
        noDataComponent={<Empty description="No tracking data available" />}
        pagination
        paginationServer
        paginationTotalRows={total}
        onChangeRowsPerPage={handleRowChange}
        onChangePage={pageChange}
        // onRowClicked={(e) => showDits(e)}
      />

      {openSMS && (
        <SmsModal
          SmsNumber={SmsNumber}
          open={openSMS}
          setOpenSMS={setOpenSMS}
          pickupLocation={pickupLocation}
          deleiveryLocation={deleiveryLocation}
          driverName={driverName}
        />
      )}
      {openStatusUpdate && (
        <UpdateStatusModal
          OnRoadData={OnRoadData}
          StatusOfTrip={StatusOfTrip}
          RowID={RowID}
          openStatusUpdate={openStatusUpdate}
          setOpenStatusUpdate={setOpenStatusUpdate}
          handleReftch={handleReftch}
        />
      )}

      {mapShow && (
        <MapModal
          mapShow={mapShow}
          closeModal={closeModal}
          DeliveryLocationLatLng={DeliveryLocationLatLng}
          PickupLocationLatLong={PickupLocationLatLong}
          tripForModal={tripForModal}
          detail={detail}
        />
      )}
      {detailShow && (
        <DetailsModal
          detail={detail}
          handleViewMap={handleViewMap}
          closeDetailModal={closeDetailModal}
          detailShow={detailShow}
        />
      )}
      {openUnAssignedmodal && (
        <UnassignDriverModal
          openUnAssignedmodal={openUnAssignedmodal}
          setUnAssignedmodal={setUnAssignedmodal}
          OnRoadDataOne={detail}
          handleReftch={handleReftch}
        />
      )}
    </>
  );
};

export default TrackingTable;
