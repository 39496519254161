import { PhotoView } from "react-photo-view";
import { Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ModalButton from "./Modals/Modal";
import SendSMSModal from "./Modals/SendSMSModal";

const RenderUserDetails = ({
  title,
  userData,
  carType,
}: {
  title: any;
  userData: any;
  carType?: any;
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [reFetch, setReFetch] = useState(false);
  const [openSMS, setOpenSMS] = useState<boolean>(false);

  const showModal = () => {
    setOpen(true);
  };

  const showSMSModal = () => {
    setOpenSMS(true);
  };

  return (
    <div className="grid grid-cols-4  my-3">
      <section>
        <p>{title}</p>
      </section>
      <section className="[&>p]:my-1">
        <div className="gap-3 flex items-center">
          <PhotoView
            src={
              userData?.image ||
              "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
            }
          >
            <Image
              src={
                userData?.image ||
                "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
              }
              fluid
              style={{
                width: "40px",
                height: "40px",
                objectFit: "cover",
                borderRadius: "100%",
                marginRight: "10px",
              }}
            />
          </PhotoView>
          <div className="[&>p]:m-0">
            <p>{`${userData?.first_name} ${userData?.last_name}`}</p>
            <p>{userData?.phone}</p>
          </div>
        </div>
      </section>
      <section className="flex items-center gap-4 h-full">
        <ModalButton
          showModal={showModal}
          userData={userData}
          title={title}
          open={open}
          setOpen={setOpen}
        />
        {title === "User" ? (
          <SendSMSModal
            open={openSMS}
            showSMSModal={showSMSModal}
            setOpenSMS={setOpenSMS}
            smsNumber={userData?.phone}
            MessageForCustomers={`Hi ${userData?.first_name} ${userData?.last_name},\nwe have noticed you requested for a ${carType} and you were unsuccessful in getting a driver. Please give us a few minutes to find a driver for you. A representative will contact you shortly. 
Thank you for using Yarp.`}
          />
        ) : (
          ""
        )}
      </section>
    </div>
  );
};

export default RenderUserDetails;
