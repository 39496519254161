import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import DataTable from "react-data-table-component";
import DateRangePicker from "react-bootstrap-daterangepicker";
import BaseService from "../../helpers/baseService";
import { global_variables } from "../../helpers/globalVariables";
import { formatCedis } from "../../functions/functions";
import { LoadingDataComponent } from "../../components/general/loadingDataComponent";
import ShowToast from "../../components/alerts/all_toasts";
import AntSelect from "../../components/shared/AntSelect/AntSelect";
import {
  ArrowDownTrayIcon,
  ArrowPathIcon,
  ClipboardDocumentListIcon,
  EllipsisVerticalIcon,
  MagnifyingGlassIcon,
  MapPinIcon,
  PrinterIcon,
} from "@heroicons/react/24/outline";
import { CSVLink } from "react-csv";
import { Tooltip } from "@material-tailwind/react";
import { PhotoView } from "react-photo-view";
import { Dropdown, Empty } from "antd";
import TripHistoryMap from "./historyMap";
import { metersToKilometers } from "../../utils/functions";
import NewDatePickerRange from "../../components/shared/newDatePicker/NewDatePicker";
import AntInput from "../../components/shared/AntInput/AntInput";
import {
  CreatedOnteSort,
  DeliveryLocation,
  PickupLocation,
} from "../../components/general/TableSortFunctions";
import { useNavigate } from "react-router-dom";

const option = [
  { value: "pickup_location", label: "Pick up Location" },
  { value: "delivery_location", label: "Delivery Location" },
];

const TripHistoryTable = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [reFetch, setReFetch] = useState(false);

  const [trip, setTrip] = useState<any>([]);
  const [total, setTotal] = useState<any>(0);

  const [skip, setSkip] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [condition, setCondition] = useState<any>("all");

  const [filtParam, setfiltParam] = useState<any>("");
  const [fltstate, setFltstate] = useState<any>(
    moment().startOf("year").format("YYYY-MM-DD")
  );
  const [fgtstate, setFgtstate] = useState<any>(
    moment().endOf("year").format("YYYY-MM-DD")
  );

  const [qry, setQry] = useState("");
  const [searchType, setSearchType] = useState<any>("");
  const [selectedTripData, setSelectedTripData] = useState<any>(null);

  const [show, setShow] = useState<boolean>(false);
  const [showDits, setShowDits] = useState<any>([]);
  const [selectData, setSelectData] = useState<any>("");

  const handleViewDetails = (data: any) => {
    setShowDits(data);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setShowDits([]);
  };

  const [openMaps, setOpenMaps] = useState(false);
  const handleOpenMap = () => {
    setOpenMaps(true);
  };
  const handleCloseMap = () => {
    setOpenMaps(false);
  };

  const getAllTrip_History = async (
    lim: number,
    sk: number,
    dateStart: any = null,
    dateEnd: any = null,
    textInput: any = null
  ) => {
    setLoading(true);
    try {
      let url = `/yarp/trip-history?sort={"updated_at": -1}&limit=${lim}&skip=${sk}`;

      switch (selectData) {
        case "pickup_location":
          if (textInput) {
            const filterQuery = {
              $or: [
                { pickup_place_name: { $regex: textInput, $options: "i" } },
              ],
            };
            const encodedFilterQuery = encodeURIComponent(
              JSON.stringify(filterQuery)
            );
            url = `&filter=${encodedFilterQuery}&sort={"updated_at": -1}&limit=${lim}&skip=${sk}`;
          }
          break;
        case "delivery_location":
          if (textInput) {
            const filterQuery = {
              $or: [
                { delivery_place_name: { $regex: textInput, $options: "i" } },
              ],
            };
            const encodedFilterQuery = encodeURIComponent(
              JSON.stringify(filterQuery)
            );
            url = `&filter=${encodedFilterQuery}&sort={"updated_at": -1}&limit=${lim}&skip=${sk}`;
          }
          break;
        default:
          url = `/yarp/trip-history?sort={"updated_at": -1}&limit=${lim}&skip=${sk}`;
          break;
      }
      if (dateStart && dateEnd) {
        url = `/yarp/trip-history?filter={"$and":[{"updated_at":{"$gte":"${dateStart}"}},{"updated_at":{"$lt":"${dateEnd}"}}]}&sort={"updated_at": -1}&limit=${lim}&skip=${sk}`;
      }

      const response = await BaseService.get_api_v2(`${url}`);
      const data = await response.data;
      setTrip(data?.payload);
      setTotal(data?.total);
      console.log("this is the trip history", data.payload);
    } catch (error: any) {
      console.log("This is the trip history error", error);
    } finally {
      setLoading(false);
    }
  };

  const handleRowChange = (a: any) => {
    setLimit(a);
  };

  const pageChange = (page: any) => {
    const newSkip = page === 1 ? 0 : (page - 1) * limit;
    setSkip(newSkip);
  };

  useEffect(() => {
    getAllTrip_History(limit, skip);
  }, [reFetch, limit, skip]);

  const onDateDataHandler = (dateObj: any) => {
    const { start, end } = dateObj;
    getAllTrip_History(limit, skip, start, end);
    if (!start && !end) {
      getAllTrip_History(limit, skip);
    }
  };

  //dropdown menus
  const items: any = [
    {
      label: (
        <button
          className="flex gap-2 items-center"
          onClick={() => handleViewDetails(selectedTripData)}
        >
          <ClipboardDocumentListIcon className="h-4 w-4" />
          Trip Info
        </button>
      ),
      key: "0",
    },
    {
      type: "divider",
    },
    {
      label: (
        <button
          className="flex gap-2 items-center"
          onClick={() => handleOpenMap()}
        >
          <MapPinIcon className="h-4 w-4" />
          Trip Map
        </button>
      ),
      key: "1",
    },
  ];

  //table columns
  const columns: any = [
    {
      name: (
        <p className="text-[14px] font-[700] text-yarpGray uppercase">
          Pickup Location
        </p>
      ),
      cell: (row: { pickup_place_name: any }) => row?.pickup_place_name,
      width: "30%",
      sortable: true,
      sortFunction: PickupLocation,
    },
    {
      name: (
        <p className="text-[14px] font-[700] text-yarpGray uppercase">
          Delivery Location
        </p>
      ),
      cell: (row: { delivery_place_name: any }) => row?.delivery_place_name,
      width: "30%",
      sortable: true,
      sortFunction: DeliveryLocation,
    },
    {
      name: (
        <p className="text-[14px] font-[700] text-yarpGray uppercase">Amount</p>
      ),
      cell: (row: { amount: any }) => formatCedis(row?.amount),
      width: "15%",
      sortable: true,
    },
    {
      name: (
        <p className="text-[14px] font-[700] text-yarpGray uppercase">
          Date / Time
        </p>
      ),
      cell: (row: any) =>
        moment(row?.updated_at).format("Do MMM YYYY - hh:mm A"),
      width: "15%",
      sortable: true,
      sortFunction: CreatedOnteSort,
    },
    // {
    //   name: (
    //     <p className="text-[14px] font-[700] text-yarpGray uppercase">Action</p>
    //   ),
    //   cell: (row: any) => (
    //     <Dropdown
    //       placement="topLeft"
    //       menu={{
    //         items,
    //       }}
    //       trigger={["click"]}
    //     >
    //       <button
    //         onClick={() => setSelectedTripData(row)}
    //         className="w-10 text-center"
    //       >
    //         <EllipsisVerticalIcon className="h-5 w-5" />
    //       </button>
    //     </Dropdown>
    //   ),
    //   width: "10%",
    // },
  ];

  //export data
  const formatDataForExport = (data: any[]) => {
    return data.map((row: any) => ({
      "Pickup Location": row?.pickup_place_name,
      "Delivery Location": row?.delivery_place_name,
      Amount: row?.amount,
      "Date / Time": moment(row?.updated_at).format("Do MMM YYYY - hh:mm A"),
    }));
  };

  const Texthandler = (arg: any) => {
    getAllTrip_History(limit, skip, null, null, arg);
    if (!arg) {
      getAllTrip_History(limit, skip);
    }
  };

  const SelectDataHandler = (selectData: string) => {
    if (!selectData) {
      setSelectData(selectData);
      getAllTrip_History(limit, skip);
    } else {
      setSelectData(selectData);
    }
  };

  return (
    <>
      <div className="d-flex d-flex justify-content-between mb-3">
        <div className="flex gap-3 mr-3">
          <Tooltip content="Reload" placement="top">
            <button
              className="w-[50px] h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center"
              onClick={() => setReFetch((prev) => !prev)}
            >
              <ArrowPathIcon className="w-5 h-5 text-yarpBlue" />
            </button>
          </Tooltip>

          <NewDatePickerRange onDateData={onDateDataHandler} />
        </div>
        <div className="d-flex">
          <div className="flex justify-center gap-6 mr-3">
            <AntSelect
              onSelectData={SelectDataHandler}
              placeholder="location"
              options={option}
            />
            <AntInput onText={Texthandler} selectInfo={selectData} />
          </div>

          <div className="flex gap-3">
            <Tooltip content="Print" placement="top">
              <button
                className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center disabled:cursor-not-allowed"
                disabled
              >
                <PrinterIcon className="w-5 h-5 text-yarpBlue" />
              </button>
            </Tooltip>
            <CSVLink data={formatDataForExport(trip)} filename="Trip History">
              <Tooltip content="Export" placement="top">
                <button className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center">
                  <ArrowDownTrayIcon className="w-5 h-5 text-yarpBlue" />
                </button>
              </Tooltip>
            </CSVLink>
          </div>
        </div>
      </div>
      <DataTable
        data={trip}
        columns={columns}
        progressPending={loading}
        progressComponent={<LoadingDataComponent />}
        noDataComponent={
          <div className="mt-4">
            <Empty />
          </div>
        }
        pagination={total >= 11}
        paginationServer={total >= 11}
        paginationTotalRows={total}
        onChangeRowsPerPage={handleRowChange}
        onChangePage={pageChange}
        pointerOnHover
        onRowClicked={(selected: any) =>
          navigate(`/trip-history-map/${selected._id}/${selected.driverId}`)
        }
        highlightOnHover
      />

      {/* trip details modal */}
      <Modal show={show} size="xl" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Trip Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-yarpGray p-3">
            <p className="text-[20px] font-[600]">Notes</p>
            <hr className="my-3" />
            <div className="p-3 rounded-md bg-[#EEF7FD]">
              <p className="text-[14px] text-yarpGray">{showDits?.notes}</p>
            </div>

            {/* info */}
            <div className="mt-[30px]">
              <p className="text-[20px] font-[600]">Trip Info</p>
              <hr className="my-3" />

              <div className="lg:grid lg:grid-cols-3 w-full gap-x-[20px]">
                <div>
                  <p className="text-[14px] font-[600] mb-0">Trip Cost</p>
                  <p className="text-[14px] font-[300]">
                    GHS {parseFloat(showDits?.amount).toFixed(2)}
                  </p>
                </div>
                <div>
                  <p className="text-[14px] font-[600] mb-0">Pick Up</p>
                  <p className="text-[14px] font-[300]">
                    {showDits?.pickup_place_name}, {showDits?.cityfrom}
                  </p>
                </div>
                <div>
                  <p className="text-[14px] font-[600] mb-0">Destination</p>
                  <p className="text-[14px] font-[300]">
                    {showDits?.delivery_place_name}, {showDits?.cityto}
                  </p>
                </div>
                <div>
                  <p className="text-[14px] font-[600] mb-0">
                    Estimated Distance
                  </p>
                  <p className="text-[14px] font-[300]">
                    {metersToKilometers(showDits?.distance)} KM
                  </p>
                </div>
                <div>
                  <p className="text-[14px] font-[600] mb-0">Truck Type</p>
                  <p className="text-[14px] font-[300]">{showDits?.car_type}</p>
                </div>
                <div>
                  <p className="text-[14px] font-[600] mb-0">Goods Weight</p>
                  <p className="text-[14px] font-[300]">
                    {showDits?.weight_load}
                  </p>
                </div>
              </div>
            </div>

            {/* images */}
            <div className="mt-[30px]">
              <p className="text-[20px] font-[600]">Item Images</p>
              <hr className="my-3" />

              <div className="lg:grid lg:grid-cols-3 w-full gap-x-[20px]">
                {showDits?.images ? (
                  showDits?.images.map((dd: any, i: any) => (
                    <PhotoView key={i} src={dd}>
                      <img
                        src={dd}
                        alt={i}
                        className="rounded-[20px] object-cover h-[300px] w-[300px]"
                      />
                    </PhotoView>
                  ))
                ) : (
                  <p className="text-[14px] font-[600]">No images available</p>
                )}
              </div>
            </div>

            {/* table */}
            <div className="mt-[30px]">
              <p className="text-[20px] font-[600]">Items</p>
              <hr className="my-3" />

              <div>
                {showDits?.items ? (
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Weight</th>
                        <th scope="col">Unit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {showDits?.items.map((d: any, index: any) => {
                        return (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{d?.name}</td>
                            <td>{d?.quantity}</td>
                            <td>{d?.weight}</td>
                            <td>{d?.unit}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                ) : (
                  <p className="text-[14px] font-[600]">No items available</p>
                )}
              </div>
            </div>

            {/* waybill */}
            <div className="mt-[30px]">
              <p className="text-[20px] font-[600]">Way Bill</p>
              <hr className="my-3" />

              <div className="flex gap-[30px]">
                <PhotoView src={showDits?.waybill}>
                  <img
                    src={showDits?.waybill}
                    alt="waybill"
                    className="rounded-[20px] object-cover h-[300px] w-[300px]"
                  />
                </PhotoView>
                <button className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center">
                  <ArrowDownTrayIcon className="w-5 h-5 text-yarpBlue" />
                </button>
              </div>
            </div>

            {/* sender */}
            <div className="mt-[30px]">
              <p className="text-[20px] font-[600]">Goods Owner</p>
              <hr className="my-3" />

              <div className="flex gap-[30px] items-center">
                <PhotoView src={showDits?.user_data?.image}>
                  <img
                    src={showDits?.user_data?.image}
                    alt="user"
                    className="rounded-full object-cover h-[70px] w-[70px]"
                  />
                </PhotoView>
                <div>
                  <p className="font-[600] text-[14px] mb-1">
                    {showDits?.user_data?.full_name}
                  </p>
                  <p className="font-[300] text-[14px] mb-0">
                    {showDits?.user_data?.phone}
                  </p>
                </div>
              </div>
            </div>

            {/* driver */}
            <div className="mt-[30px]">
              <p className="text-[20px] font-[600]">Driver</p>
              <hr className="my-3" />

              <div className="flex gap-[30px] items-center">
                <PhotoView src={showDits?.driver_data?.image}>
                  <img
                    src={showDits?.driver_data?.image}
                    alt="user"
                    className="rounded-full object-cover h-[70px] w-[70px]"
                  />
                </PhotoView>
                <div>
                  <p className="font-[600] text-[14px] mb-1">
                    {showDits?.driver_data?.full_name}
                  </p>
                  <p className="font-[300] text-[14px] mb-0">
                    {showDits?.driver_data?.phone}
                  </p>
                </div>
              </div>
            </div>

            {/* pick person */}
            <div className="mt-[30px]">
              <p className="text-[20px] font-[600]">Pickup Person</p>
              <hr className="my-3" />

              <div className="lg:grid lg:grid-cols-3 w-full">
                <div>
                  <p className="font-[600] text-[14px] mb-1">Name</p>
                  <p className="font-[300] text-[14px]">
                    {showDits?.pickup_person_name}
                  </p>
                </div>
                <div>
                  <p className="font-[600] text-[14px] mb-1">Contact</p>
                  <p className="font-[300] text-[14px] mb-0">
                    {showDits?.pickup_person_contact}
                  </p>
                </div>
              </div>
            </div>

            <hr className="my-4" />
            <div className="flex justify-end">
              <Button variant="outlined" onClick={handleClose}>
                Close
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* open map modal */}
      {openMaps ? (
        <TripHistoryMap
          isOpened={openMaps}
          handleCloseModal={handleCloseMap}
          tripData={selectedTripData}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default TripHistoryTable;
